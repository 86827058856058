import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
// import SEO, { ImageProps, Profile, ArticleProps, HelmetProps } from "../components/Common/SEO"
import { HeroTitle } from "../components/Styling/Titles"
import { Ingress } from "../components/Styling/Body"
import { toDisplayDate } from "../components/Common/DateConverter"

const Personvern = ({ location, data }) => {
  const personvernsData = data.contentfulGeneric

  let prettyDate: string = personvernsData.updatedAt ? personvernsData.updatedAt : undefined
  prettyDate = toDisplayDate(personvernsData.updatedAt)
  // TODO: Bytte tomme strings med rett data fra contentful. Så kopiere samme løsning til alle templates
  // const imageProps: ImageProps = {
  //   imageUrl: topic.heroImage,
  //   imageSecureUrl: topic.heroImage,
  //   imageHigth: "",
  //   imageWitdh: "",
  //   imageAlt: "Alt fra cloudinary",
  //   imageType: "",
  // }
  // const profile: Profile = {
  //   profileFirstName: "Ola",
  //   profileLastName: "Norman",
  // }
  // const personvernProps: PersonvernProps = {
  //   publishTime: "",
  //   modifiedTime: "",
  //   expirationTime: "",
  //   section: "",
  //   tags: ["", ""],
  //   authors: [profile],
  // }
  // const helmetProps: HelmetProps = {
  //  title: personvernsData.title,
  // slug: personvernsData.slug,
  // contentType: "article",
  //  locale: personvernsData.node_locale,
  //  isSecret: false,
  //   articleMetaTags: articleProps,
  // images: [imageProps],
  // }
  return (
    <>
      {/* <SEO {...helmetProps} /> */}
      <main>
        <div className="bg-white relative max-w-7xl mx-auto mt-6 ">
          <header>
            <div className="header relative text-center break-normal break-words mb-8 md:mb-2 md:px-6">
              <HeroTitle>{personvernsData.title}</HeroTitle>
            </div>
          </header>
          <div className="text-center mt-16 text-author-500 text-opacity-50  ">
            <p>Sist oppdatert: {prettyDate}</p>
          </div>

          <div className="px-4 sm:px-0 max-w-s sm:max-w-lg md:max-w-xl xl:max-w-84 mx-auto mt-16 ">
            <Ingress>{personvernsData.ingress.ingress}</Ingress>
          </div>

          <div className="body mr-8 ml-8 py-8 md:ml-40 md:mr-40 lg:mr-56 lg:ml-56 max-w-screen">
            <MDXRenderer>{personvernsData.body.childMdx.body}</MDXRenderer>
          </div>
        </div>
      </main>
    </>
  )
}

export const query = graphql`
  {
    contentfulGeneric(slug: { eq: "personvern" }, node_locale: { eq: "nb-NO" }) {
      id
      slug
      title
      node_locale
      metadataDescription
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss ")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss ")
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default Personvern
